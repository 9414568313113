import { Asset } from '@graphcommerce/graphcms-ui'
import { Row, responsiveVal } from '@graphcommerce/next-ui'
import { Box, Button } from '@mui/material'
import { BannerContent } from '../Components/BannerContent'
import { RowContentBannerCtaFragment } from '../RowContentBannerCta.gql'

export function FullWidth(props: RowContentBannerCtaFragment) {
  const { bannerAsset, bannerButton, ...rest } = props
  const mimeType = bannerAsset?.mimeType

  if (!mimeType) return null

  return (
    <Row
      maxWidth='lg'
      sx={(theme) => ({
        mb: theme.spacings.xxl,
      })}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          position: 'relative',
          marginBottom: theme.spacings.md,
        })}
      >
        {bannerAsset && (
          <Asset
            asset={bannerAsset}
            sizes={responsiveVal(350, 1480)}
            quality={84}
            layout='fill'
            pictureProps={{
              sx: {
                flex: 1,
                height: responsiveVal(123, 512),
              },
            }}
            sx={[
              {
                objectFit: 'cover',
                objectPosition: 'center 40%',
              },
              bannerAsset.mimeType === 'video/mp4' && {
                width: '100%',
                height: responsiveVal(123, 512),
              },
            ]}
          />
        )}

        {bannerButton && (
          <Button
            href={bannerButton.buttonUrl ?? ''}
            size='large'
            variant='pill'
            color={bannerButton.buttonColor ?? 'primary'}
            sx={(theme) => ({
              position: 'absolute',
              right: theme.spacings.sm,
              bottom: theme.spacings.sm,
              ...theme.typography.h5,
            })}
          >
            {bannerButton.buttonText}
          </Button>
        )}
      </Box>

      <BannerContent {...rest} />
    </Row>
  )
}
